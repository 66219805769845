import { Component } from "@angular/core";

@Component({
  selector: "create-post-tutorial-page",
  templateUrl: "./create-post-tutorial-page.component.html",
  styleUrls: ["./create-post-tutorial-page.component.scss"],
})
export class CreatePostTutorialPageComponent {
  constructor() {}
}
