<div class="buy-deso__container">
  <!-- Top Bar-->
  <div class="text-truncate mint-nft__title ml-16px" [ngStyle]="{ 'max-width': globalVars.isMobile() ? '350px' : '' }">
    {{ "diamonds_pages.diamonds_from" | transloco }} @{{ senderUsername }}
  </div>
  <div class="global__top-bar__height"></div>
  <simple-center-loader *ngIf="loadingFirstPage && !loadingNextPage"></simple-center-loader>
  <div class="flex-grow-1" id="diamond-scroller">
    <div #uiScroll *uiScroll="let diamondPost of datasource" class="border-bottom border-color-grey">
      <div
        *ngIf="diamondPost.ShowDiamondDivider"
        class="light-grey-divider border-bottom border-color-grey d-flex align-items-center justify-content-center flex-row"
      >
        <div class="py-10px">
          <i
            class="icon-diamond fc-blue fs-20px"
            *ngFor="let _ of diamondArray(diamondPost.Post.DiamondsFromSender)"
          ></i>
        </div>
      </div>
      <feed-post
        *ngIf="diamondPost.Post.ProfileEntryResponse"
        [showReplyingTo]="true"
        [contentShouldLinkToThread]="true"
        [includePaddingOnPost]="true"
        [post]="diamondPost.Post"
        [blocked]="globalVars.hasUserBlockedCreator(diamondPost.Post.ProfileEntryResponse.PublicKeyBase58Check)"
        [afterCommentCreatedCallback]="_prependComment.bind(this, post)"
      ></feed-post>
      <div *ngFor="let comment of diamondPost.Post.Comments">
        <div class="px-15px pb-15px post-thread__subcomment-container">
          <feed-post
            [includePaddingOnPost]="false"
            [post]="comment"
            [parentPost]="post"
            [contentShouldLinkToThread]="true"
            [showIconRow]="false"
            [showDropdown]="false"
            [showReplyingToContent]="false"
            [afterCommentCreatedCallback]="_prependComment.bind(this, post)"
          ></feed-post>
        </div>
      </div>
    </div>
    <simple-center-loader [height]="200" *ngIf="loadingNextPage && !loadingFirstPage"></simple-center-loader>
  </div>
</div>
