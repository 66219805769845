<div class="container">
  <div class="row">
    <div class="col">
      <h1 class="western" align="left">
        Last updated:
        <br />
        November 1, 2020
      </h1>
      <p class="western" align="left">&nbsp;</p>
      <h2 class="western" align="left">Terms of Service</h2>
      <p class="western" align="left">
        This is a user interface (the &ldquo;Interface&rdquo;) that provides access to the DeSo blockchain (the
        "Blockchain"),
        <strong>which is a fully-decentralized protocol that no individual or corporate entity controls.</strong>
      </p>
      <p class="western" align="left">&nbsp;</p>
      <p class="western" align="left">
        This Terms of Service Agreement (the &ldquo;Agreement&rdquo;) explains the terms and conditions by which you may
        access and use the Interface. You must read this Agreement carefully. By accessing or using the Interface, you
        signify that you have read, understand, and agree to be bound by this Agreement in its entirety. If you do not
        agree, you are not authorized to access or use the Interface.
      </p>
      <p class="western" align="left">&nbsp;</p>
      <h3 class="western" align="left">1. Modification of this Agreement</h3>
      <p class="western" align="left">
        The operators of the Interface
        <em><strong>and</strong></em>
        any and all developers of the Blockchain (
        <span style="color: #000000">
          <span>
            <span><span>collectively, the</span></span>
          </span>
        </span>
        "Community") reserve the right, in their sole discretion, to modify this Agreement from time to time. If the
        Community makes any modifications, the Community will notify you by updating the date at the top of the
        Agreement. All modifications will be effective when they are posted, and your continued use of the Interface
        will serve as confirmation of your acceptance of those modifications. If you do not agree with any modifications
        to this Agreement, you must immediately stop accessing and using the Interface.
      </p>
      <p class="western" align="left">&nbsp;</p>
      <h3 class="western" align="left">2. Eligibility</h3>
      <p class="western" align="left">
        To access or use the Interface, you represent that you are at least eighteen years old and have the full right,
        power, and authority to enter into and comply with the terms and conditions of this Agreement on behalf of
        yourself and any company or legal entity for which you may access or use the Interface. You further represent
        that you are not a citizen, resident, or member of any jurisdiction or group that is subject to economic
        sanctions by the United States, or where your use of the Interface would be illegal or otherwise violate any
        applicable law. You further represent that your access and use of the Interface will fully comply with all
        applicable laws and regulations, and that you will not access or use the Interface to conduct, promote, or
        otherwise facilitate any illegal activity.
      </p>
      <p class="western" align="left">&nbsp;</p>
      <h3 class="western" align="left">3. Prohibited Activity</h3>
      <p class="western" align="left">
        You agree not to engage in, or attempt to engage in, any of the following categories of prohibited activity in
        relation to your access and use of the Interface:
      </p>
      <p class="western" align="left">&nbsp;</p>
      <ul>
        <li>
          <p class="western" align="left">
            Any Unlawful Conduct. Activity that violates any applicable law, rule, or regulation of the United States or
            another relevant jurisdiction, including (but not limited to) the restrictions and regulatory requirements
            imposed by U.S. law.
          </p>
        </li>
      </ul>
      <p class="western" align="left">&nbsp;</p>
      <h3 class="western" align="left">4. No Professional Advice</h3>
      <p class="western" align="left">
        All information provided by the Interface is for informational purposes only and should not be construed as
        professional advice. You should not take, or refrain from taking, any action based on any information contained
        in the Interface. Before you make any financial, legal, or other decisions involving the Interface, you should
        seek independent professional advice from an individual who is licensed and qualified in the area for which such
        advice would be appropriate.
      </p>
      <p class="western" align="left">&nbsp;</p>
      <h3 class="western" align="left">5. No Warranties</h3>
      <p class="western" align="left">
        The Interface is provided on an &ldquo;AS IS&rdquo; and &ldquo;AS AVAILABLE&rdquo; basis. To the fullest extent
        permitted by law, the Community disclaims any representations and warranties of any kind, whether express,
        implied, or statutory, including (but not limited to) the warranties of merchantability and fitness for a
        particular purpose. You acknowledge and agree that your use of the Interface is at your own risk. The Community
        does not represent or warrant that access to the Interface will be continuous, uninterrupted, timely, or secure;
        that the information contained in the Interface will be accurate, reliable, complete, or current; or that the
        Interface will be free from errors, defects, viruses, or other harmful elements. No advice, information, or
        statement that the Community makes should be treated as creating any warranty concerning the Interface. The
        Community does not endorse, guarantee, or assume responsibility for any advertisements, offers, or statements
        made by third parties concerning the Interface.
      </p>
      <p class="western" align="left">&nbsp;</p>
      <p class="western" align="left">
        The Community has done its best to ensure that all software is secure and free of errors. However, for the
        avoidance of doubt, you acknowledge and agree that purchases and transfers of Bitcoin or the DESO cryptocurrency
        ("DESO") made
        <span style="color: #000000">
          <span style="font-family: 'Liberation Serif', serif">
            <span style="font-size: medium"><span lang="en-US">using</span></span>
          </span>
        </span>
        the Interface are at your own risk, and that if any errors, defects, viruses, or other harmful elements result
        in the loss of DESO or Bitcoin, the Community cannot be held responsible or liable. You further release any and
        all claim to Bitcoin deposited into the Interface once said Bitcoin has been successfully converted into DESO. A
        conversion of Bitcoin into DESO is considered complete once the conversion transaction has been mined into a
        block on the
        <span style="color: #000000">
          <span style="font-family: 'Liberation Serif', serif">
            <span style="font-size: medium"><span lang="en-US">B</span></span>
          </span>
        </span>
        lockchain. For the avoidance of doubt, Bitcoin that you deposit into the Interface and convert into DESO is no
        longer your property
        <span style="color: #000000">
          <span style="font-family: 'Liberation Serif', serif">
            <span style="font-size: medium"><span lang="en-US">once</span></span>
          </span>
        </span>
        the conversion into DESO is complete.
      </p>
      <p class="western" align="left">&nbsp;</p>
      <p class="western" align="left">
        You further acknowledge that conversions of Bitcoin into DESO are executed through a fully-decentralized
        transaction with the Blockchain, similar to cryptocurrency mining, and that there is no centralized issuer of
        DESO, and therefore no counter-party for such transactions.
      </p>
      <p class="western" align="left">&nbsp;</p>
      <h3 class="western" align="left">6. Consumptive Use</h3>
      <p class="western" align="left">
        When you purchase or otherwise acquire DESO or Creator Coins (collectively, “DESO Coins”), you represent,
        warrant and agree that:
      </p>
      <p class="western mt-3" align="left">
        (i) you are acquiring DESO Coins solely for consumptive use in connection with the Blockchain and not for
        distribution;
        <br />
        <br />
        (ii) you are not acquiring DESO Coins as an investment and you have no expectation of economic benefit or profit
        as a holder of DESO Coins;
        <br />
        <br />
        (iii) you are acquiring DESO Coins for your own use and not with a view to sell the DESO Coins to anyone else;
        <br />
        <br />
        (iv) you are not acquiring any equity or other ownership or legal interest in the Interface or the Blockchain by
        virtue of owning DESO Coins;
        <br />
        <br />
        (v) you will not portray DESO Coins as an investment or an opportunity to obtain an economic benefit or profit;
        <br />
        <br />
        (vi) you have an adequate understanding of the functionality and characteristics of DESO Coins and the
        differences between DESO and Creator Coins;
        <br />
        <br />
        (vii) your purchase and use of DESO Coins complies with applicable laws and regulations in your jurisdiction,
        including, without limitation, legal capacity, regulatory restrictions and governmental consents; and
        <br />
        <br />
        (viii) you will not use DESO Coins for any illegal purpose within or outside of the Blockchain or the Interface.
      </p>
      <h3 class="western mt-3" align="left">7. No Fiduciary Duties</h3>
      <p class="western" align="left">
        This Agreement is not intended to, and does not, create or impose any fiduciary duties on the Community. To the
        fullest extent permitted by law, you acknowledge and agree that the Community owes no fiduciary duties or
        liabilities to you or any other party, and that to the extent any such duties or liabilities may exist at law or
        in equity, those duties and liabilities are hereby irrevocably disclaimed, waived, and eliminated. You further
        agree that the only duties and obligations that the Community owes you are those set out expressly in this
        Agreement.
      </p>
      <p class="western" align="left">&nbsp;</p>
      <h3 class="western" align="left">8. Compliance Obligations</h3>
      <p class="western" align="left">
        By accessing or using the Interface, you agree that you are solely and entirely responsible for compliance with
        all laws and regulations that may apply to you. You may not use the Interface if you are a citizen, resident, or
        member of any jurisdiction or group that is subject to economic sanctions by the United States, or if your use
        of the Interface would be illegal or otherwise violate any applicable law. The Interface and all of its contents
        are solely directed to individuals, companies, and other entities located within the United States.
      </p>
      <p class="western" align="left">&nbsp;</p>
      <h3 class="western" align="left">9. Assumption of Risk</h3>
      <p class="western" align="left">
        By accessing and using the Interface, you represent that you understand the inherent risks associated with using
        cryptographic and blockchain-based systems, and that you have a working knowledge of the usage and intricacies
        of digital assets such as bitcoin (BTC), DESO, and other digital tokens. You further understand that the markets
        for these digital assets are highly volatile due to factors including (but not limited to) adoption,
        speculation, technology, security, and regulation. You acknowledge that the cost and speed of transacting with
        cryptographic and blockchain-based systems such as Bitcoin or DESO are variable and may increase dramatically at
        any time. You further acknowledge the risk that your digital assets may lose some or all of their value. You
        further acknowledge that the Community is not responsible for any of these variables or risks, does not own or
        control the Blockchain, and cannot be held liable for any resulting losses that you experience while accessing
        or using the Interface. Accordingly, you understand and agree to assume full responsibility for all of the risks
        of accessing and using the Interface and interacting with the Blockchain.
      </p>
      <p class="western" align="left">&nbsp;</p>
      <h3 class="western" align="left">10. Third-Party Resources and Promotions</h3>
      <p class="western" align="left">
        The Interface may contain references or links to third-party resources, including (but not limited to)
        information, materials, products, or services, that the Community does not own or control. In addition, third
        parties may offer promotions related to your access and use of the Interface. The Community does not endorse or
        assume any responsibility for any such resources or promotions. If you access any such resources or participate
        in any such promotions, you do so at your own risk, and you understand that this Agreement does not apply to
        your dealings or relationships with any third parties. You expressly relieve the Community of any and all
        liability arising from your use of any such resources or participation in any such promotions.
      </p>
      <p class="western" align="left">&nbsp;</p>
      <h3 class="western" align="left">11. Release of Claims</h3>
      <p class="western" align="left">
        You expressly agree that you assume all risks in connection with your access and use of the Interface and your
        interaction with the Blockchain. You further expressly waive and release the Community from any and all
        liability, claims, causes of action, or damages arising from or in any way relating to your use of the Interface
        and your interaction with the Blockchain. If you are a California resident, you waive the benefits and
        protections of California Civil Code &sect; 1542, which provides: &ldquo;[a] general release does not extend to
        claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of
        executing the release and that, if known by him or her, would have materially affected his or her settlement
        with the debtor or released party.&rdquo;
      </p>
      <p class="western" align="left">&nbsp;</p>
      <h3 class="western" align="left">12. Indemnity</h3>
      <p class="western" align="left">
        You agree to hold harmless, release, defend, and indemnify the Community from and against all claims, damages,
        obligations, losses, liabilities, costs, and expenses arising from: (a) your access and use of the Interface;
        (b) your violation of any term or condition of this Agreement, the right of any third party, or any other
        applicable law, rule, or regulation; and (c) any other party&rsquo;s access and use of the Interface with your
        assistance or using any device or account that you own or control.
      </p>
      <p class="western" align="left">&nbsp;</p>
      <h3 class="western" align="left">13. Limitation of Liability</h3>
      <p class="western" align="left">
        Under no circumstances shall the Community be liable to you for any indirect, punitive, incidental, special,
        consequential, or exemplary damages, including (but not limited to) damages for loss of profits, goodwill, use,
        data, or other intangible property, arising out of or relating to any access or use of the Interface, nor will
        the Community be responsible for any damage, loss, or injury resulting from hacking, tampering, or other
        unauthorized access or use of the Interface or the information contained within it. The Community assumes no
        liability or responsibility for any: (a) errors, mistakes, or inaccuracies of content; (b) personal injury or
        property damage, of any nature whatsoever, resulting from any access or use of the Interface; (c) unauthorized
        access or use of any secure server or database in our control, or the use of any information or data stored
        therein; (d) interruption or cessation of function related to the Interface; (e) bugs, viruses, trojan horses,
        or the like that may be transmitted to or through the Interface; (f) errors or omissions in, or loss or damage
        incurred as a result of the use of, any content made available through the Interface; and (g) the defamatory,
        offensive, or illegal conduct of any third party. Under no circumstances shall the Community be liable to you
        for any claims, proceedings, liabilities, obligations, damages, losses, or costs. This limitation of liability
        applies regardless of whether the alleged liability is based on contract, tort, negligence, strict liability, or
        any other basis, and even if the Community has been advised of the possibility of such liability. Some
        jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of certain
        liabilities and damages. Accordingly, some of the disclaimers and limitations set forth in this Agreement may
        not apply to you. This limitation of liability shall apply to the fullest extent permitted by law.
      </p>
      <p class="western" align="left">&nbsp;</p>
      <h3 class="western" align="left">14. Dispute Resolution</h3>
      <p class="western" align="left">
        The Community will use its best efforts to resolve any potential disputes through informal, good faith
        negotiations. If the Community isn't able to reach an informal resolution within sixty days of contact, then you
        and the Community both agree to resolve the potential dispute according to the process set forth below.
      </p>
      <p class="western" align="left">&nbsp;</p>
      <p class="western" align="left">
        Any claim or controversy arising out of or relating to the Interface, this Agreement, or any other acts or
        omissions for which you may contend that the Community liable, including (but not limited to) any claim or
        controversy as to arbitrability (&ldquo;Dispute&rdquo;), shall be finally and exclusively settled by arbitration
        under the JAMS Optional Expedited Arbitration Procedures. You understand that you are required to resolve all
        Disputes by binding arbitration. The arbitration shall be held on a confidential basis before a single
        arbitrator, who shall be selected pursuant to JAMS rules. The arbitration will be held in Jackson Hole, Wyoming
        unless you and the Community both agree to hold it elsewhere. Unless you and the Community agree otherwise, the
        arbitrator may not consolidate your claims with those of any other party. Any judgment on the award rendered by
        the arbitrator may be entered in any court of competent jurisdiction.
      </p>
      <p class="western" align="left">&nbsp;</p>
      <h3 class="western" align="left">15. Class Action and Jury Trial Waiver</h3>
      <p class="western" align="left">
        You must bring any and all Disputes against the Community in your individual capacity and not as a plaintiff in
        or member of any purported class action, collective action, private attorney general action, or other
        representative proceeding. This provision applies to class arbitration. You and the Community both agree to
        waive the right to demand a trial by jury.
      </p>
      <p class="western" align="left">&nbsp;</p>
      <h3 class="western" align="left">16. Governing Law</h3>
      <p class="western" align="left">
        You agree that the laws of the State of Wyoming, without regard to principles of conflict of laws, govern this
        Agreement and any Dispute between you and the Community. You further agree that the Interface shall be deemed to
        be based solely in the State of Wyoming, and that although the Interface may be available in other
        jurisdictions, its availability does not give rise to general or specific personal jurisdiction in any forum
        outside the State of Wyoming. Any arbitration conducted pursuant to this Agreement shall be governed by the
        Federal Arbitration Act. You agree that Jackson Hole, Wyoming is the proper forum for any appeals of an
        arbitration award or for court proceedings in the event that this Agreement&rsquo;s binding arbitration clause
        is found to be unenforceable.
      </p>
      <p class="western" align="left">&nbsp;</p>
      <p class="western" align="left">&nbsp;</p>
      <h3 class="western" align="left" id="commitment-to-removing-reserved-profiles">
        17. Commitment to Removing Reserved Profiles Upon Request
      </h3>
      <p class="western" align="left">&nbsp;</p>
      <p class="western" align="left">
        The initial developers of the DeSo blockchain reserved a subset of Twitter users' profiles early on in order to
        prevent squatting and impersonation.
      </p>
      <p class="western" align="left">&nbsp;</p>
      <p class="western" align="left">
        This being said, any user with a reserved profile who would like to have their profile removed from bitclout.com
        can request this removal by messaging the @deso handle on Twitter. This is needed in order to authenticate the
        owner of the Twitter handle corresponding to the reserved profile. bitclout.com agrees to remove the reserved
        profile of any user who messages @deso with the corresponding Twitter account.
      </p>
      <p class="western" align="left">&nbsp;</p>
      <p class="western" align="left">&nbsp;</p>
    </div>
  </div>
</div>
