<div class="d-flex js-creator-profile-top-card-container creator-profile__top-card-profile-row">
  <div
    [ngClass]="{
      nft__avatar: profile?.ExtraData?.NFTProfilePictureUrl
    }"
    class="creator-profile__avatar position-relative"
    [avatar]="profile.PublicKeyBase58Check"
    [nftProfileUrl]="profile?.ExtraData?.NFTProfilePictureUrl"
  ></div>
  <div
    [ngClass]="{
      nft__avatar: profile?.ExtraData?.NFTProfilePictureUrl
    }"
    class="creator-profile__avatar-border"
  ></div>
  <div class="d-flex flex-column creator-profile__username-price">
    <div class="fs-18px font-weight-bold d-flex align-items-center">
      @{{ profile.Username }}

      <div
        *ngIf="profile.IsReserved && !profile.IsVerified"
        (click)="tooltip.toggle()"
        class="ml-10px cursor-pointer fs-12px lh-12px fc-muted"
        matTooltipClass="global__mat-tooltip global__mat-tooltip-font-size"
        [matTooltip]="'This profile is reserved'"
        #tooltip="matTooltip"
      >
        <i class="far fa-clock fa-md align-middle"></i>
      </div>

      <div
        *ngIf="profile.IsVerified"
        (click)="tooltip.toggle()"
        class="ml-1 mb-1 cursor-pointer fs-16px text-primary"
        matTooltipClass="global__mat-tooltip global__mat-tooltip-font-size"
        [matTooltip]="'This account is verified'"
        #tooltip="matTooltip"
      >
        <i-feather name="check-circle" class="feather-medium"></i-feather>
      </div>
    </div>
    <div *ngIf="profile.CoinPriceDeSoNanos > 0" style="whitespace: nowrap" class="d-flex">
      <div class="font-weight-bold" style="display: inline">
        ≈{{ globalVars.nanosToUSD(profile.CoinPriceDeSoNanos, 2) }}
        <span class="fc-muted font-weight-normal">{{ "creator_profile_top_card.coin_price" | transloco }}</span>
      </div>
    </div>
    <div *ngIf="profile?.ExtraData?.NFTProfilePicturePostHashHex">
      <a
        [routerLink]="['/' + globalVars.RouteNames.NFT, profile?.ExtraData?.NFTProfilePicturePostHashHex]"
        class="fs-14px fc-secondary"
      >
        <u>View NFT</u>
      </a>
    </div>
  </div>
</div>
