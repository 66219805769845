import { Component } from "@angular/core";

@Component({
  selector: "buy-creator-coins-tutorial-page",
  templateUrl: "./buy-creator-coins-tutorial-page.component.html",
  styleUrls: ["./buy-creator-coins-tutorial-page.component.scss"],
})
export class BuyCreatorCoinsTutorialPageComponent {
  constructor() {}
}
