<div class="right-bar-creators__wallet-container">
  <header class="mb-3">
    <h2 class="fs-16px font-weight-bold">@{{ this.profile.Username }} Creator Earnings</h2>
    <a
      target="_blank"
      class="fs-12px font-weight-normal mt-5px fc-secondary"
      [href]="'https://openprosper.com/u/' + this.profile.Username"
    >
      <i class="fas fa-external-link-alt pr-5px"></i>
      Powered by OpenProsper
    </a>
  </header>
  <div *ngIf="this.isLoading" class="text-center py-4">
    <span class="fas fa-spinner fa-spin"></span>
    <div class="fs-14px">Calculating latest earnings...</div>
  </div>
  <div *ngIf="!this.isLoading && this.apiError" class="text-center">
    {{ this.apiError }}
  </div>
  <ng-container *ngIf="!this.isLoading && !this.apiError && this.earningsDetail">
    <div class="d-flex mb-2">
      <div>Total Earnings</div>
      <div class="ml-auto">≈ {{ this.globalVars.nanosToUSD(this.totalEarningsNanos) }}</div>
    </div>
    <div class="d-flex mb-2">
      <div>Diamonds</div>
      <div class="ml-auto">≈ {{ this.globalVars.nanosToUSD(this.earningsDetail.DiamondsReceivedNanos) }}</div>
    </div>
    <div class="mb-2">
      <div class="d-flex">
        <div>NFT Earnings</div>
        <div class="ml-auto">≈ {{ this.globalVars.nanosToUSD(this.earningsDetail.NFTEarnings) }}</div>
      </div>
      <div class="fs-14px" *ngIf="this.earningsDetail.NFTEarnings > 0">
        <button
          id="nft-earnings-breakdown-btn"
          class="link border-none p-0 background-color-transparent fs-12px"
          [attr.aria-expanded]="this.isNftEarningsBreakdownOpen"
          (click)="this.isNftEarningsBreakdownOpen = !this.isNftEarningsBreakdownOpen"
        >
          <span *ngIf="this.isNftEarningsBreakdownOpen">Hide breakdown</span>
          <span *ngIf="!this.isNftEarningsBreakdownOpen">See breakdown</span>
        </button>
        <ul class="pl-2" *ngIf="this.isNftEarningsBreakdownOpen" aria-labelledby="nft-earnings-breakdown-btn">
          <li class="d-flex">
            <div>Primary Sales</div>
            <div class="ml-auto">≈ {{ this.globalVars.nanosToUSD(this.nftPrimarySalesNanos) }}</div>
          </li>
          <li class="d-flex">
            <div>Royalties</div>
            <div class="ml-auto">≈ {{ this.globalVars.nanosToUSD(this.nftRoyaltiesNanos) }}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="d-flex mb-2">
      <div>Creator Coin</div>
      <div class="ml-auto">≈ {{ this.globalVars.nanosToUSD(this.earningsDetail.FREarnedNanos) }}</div>
    </div>
  </ng-container>
</div>
